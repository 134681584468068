import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { acceptInvitationMutation } from "../user/apiOperations.ts";

export function AcceptInvitationModal({ uuid, name }: { uuid: string; name: string }) {
    const acceptMutation = useStandardMutation(acceptInvitationMutation);

    async function acceptInvitation() {
        await acceptMutation.mutateAsync({ uuid });
    }

    return (
        <WModal button={<WModalButton inTable color="success" label="Accept" />}>
            <WModalContent title="Accept Invitation" label="Accept" modalAction={acceptInvitation}>
                <p className="pb-4">
                    Accepting this access invitation will grant you permission to act as "{name}" and perform all
                    operations on behalf of the user.
                </p>
                <p>
                    Note! The owner will know if you have accepted this invitation. They will not be able to access your
                    account and see or edit any of your resources.
                </p>
            </WModalContent>
        </WModal>
    );
}
