import { EditableText, type EditableTextProps } from "../forms/EditableText.tsx";
import { DetailsTableRow, type DetailsTableRowProps } from "./DetailsTableRow.tsx";

export interface DetailsTableNameProps
    extends Omit<EditableTextProps, "defaultValue" | "defaultIsEditing" | "validate"> {
    title?: DetailsTableRowProps["title"];
}

export function DetailsTableName({ title = "Name:", ...editableProps }: DetailsTableNameProps) {
    return (
        <DetailsTableRow title={title}>
            <EditableText placeholder="Add name" {...editableProps} />
        </DetailsTableRow>
    );
}
