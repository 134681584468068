import C from "../../components/sidebar/StandardSidebar.module.css";

import { useAtomValue } from "jotai/react";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { QueryErrorBlocks } from "../../components/sidebar/ListErrorBoundary.tsx";
import { PriceBlock } from "../../components/sidebar/PriceBlock.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { SkeletonBlock, StandardBlock } from "../../components/sidebar/StandardSidebar";
import { cn } from "../../utils/classNames.ts";
import { ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { hoursPerMonth } from "../pricing/hourlyToMonthly.ts";
import { objectStorageLink } from "./links.ts";
import type { StorageWithPrice } from "./objectStorage/apiOperations.ts";
import { type StorageListData, storageListQueryWithPricesAtom } from "./storageListQuery.ts";

const sortFields = [
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => i.name),
    },
    { id: "size_bytes", title: "Size", getValue: (i) => i.size_bytes },
    { id: "price", title: "Monthly Cost", getValue: (i) => i.price.hourly * hoursPerMonth },
] satisfies SimpleSortField<StorageWithPrice>[];

export function StorageList() {
    return (
        <SidebarList
            data={(props) => <StorageData {...props} />}
            title="Storage"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Storage",
                action: ResourceCreateLinks.bucket,
            }}
            listId="storage"
        />
    );
}

export default StorageList;

export function StorageData(props: SidebarListState) {
    const query = useAtomValue(storageListQueryWithPricesAtom);
    const { data, isPending } = query;
    return (
        <>
            <QueryErrorBlocks query={query} />
            <StorageDataInner {...props} isPending={isPending} data={data ? [...data.values()] : []} />
            {isPending && <SkeletonBlock />}
        </>
    );
}

interface StorageInnerProps extends SidebarListState {
    data: StorageListData;
    isPending: boolean;
}

function StorageDataInner({ queryString, sortId, sortAsc, data, isPending }: StorageInnerProps) {
    let storageItems = getFilteredItems(queryString, [...data.values()], (item) => [item.name]);
    storageItems = getSortedItems(sortFields, { sortId, sortAsc }, storageItems);

    const storageList = storageItems.map((obj) => <StorageBlock key={obj.name} item={obj} />);

    const empty = <EmptyItem icon="jp-stack-icon">Create a new storage bucket and start storing your data.</EmptyItem>;

    return storageList.length ? (
        storageList
    ) : queryString ? (
        <NoResults />
    ) : isPending ? /* If we are still loading, we can not know if we are empty, so just show nothing */ null : (
        empty
    );
}

export function StorageBlock({ item }: { item: StorageWithPrice }) {
    const { size_bytes, name, num_objects } = item;
    const sizeGB = (size_bytes / 1024 / 1024 / 1024).toFixed(2);

    return (
        <StandardBlock link={objectStorageLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-stack-icon size-0.875rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{name}</b>
                <div className="text-ellipsis text-primary">{num_objects} object(s)</div>
            </div>

            <div className={C.StandardBlockType}>
                <div className="text-primary">{sizeGB} GB</div>
            </div>

            <PriceBlock price={item.price} />
        </StandardBlock>
    );
}
