import C from "./UserAvatar.module.css";

import { Link } from "@tanstack/react-router";
import type { User } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import { adminEnabled, useIsInAdminMode } from "../../admin/adminMode.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { userDataAtom, userIsAdminAtom } from "./apiOperations.ts";

export function NameAvatar({ name, size = C.HeaderSize }: { name: string; size?: string }) {
    return <div className={cn(C.Avatar, size)}>{name.slice(0, 2)}</div>;
}

export function UserAvatar({ user, inHeader }: { user: User; inHeader?: boolean }) {
    const {
        name,
        profile_data: { avatar },
    } = user;

    return (
        <div className={cn(C.Avatar, inHeader ? C.HeaderSize : C.UserSize)}>
            {avatar ? <img src={avatar} alt={name} /> : name.slice(0, 2)}
        </div>
    );
}

export function UserSwitch() {
    const userData = useSuspenseQueryAtom(userDataAtom);
    const isAdmin = useAtomValue(userIsAdminAtom);
    const isInAdminMode = useIsInAdminMode();

    const {
        name,
        profile_data: { avatar },
    } = userData;

    return adminEnabled && isAdmin ? (
        <Link
            to={isInAdminMode ? "/" : "/admin"}
            className={cn(C.Switch)}
            data-selected={isInAdminMode ? true : undefined}
        >
            <div className={C.indicator}>
                {avatar ? <img src={avatar} alt={name} /> : <div className={C.Avatar}>{name.slice(0, 2)}</div>}
            </div>
            <div className={C.label}>Admin</div>
        </Link>
    ) : (
        <UserAvatar inHeader user={userData} />
    );
}
