import C from "../../components/sidebar/StandardSidebar.module.css";

import { datetimeToDate } from "@warrenio/api-spec/conversion";
import { Fragment, type ReactNode } from "react";
import { Badge } from "../../components/Badge.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { addressCompareBy, localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { QueryErrorBlocks } from "../../components/sidebar/ListErrorBoundary.tsx";
import { PriceBlock } from "../../components/sidebar/PriceBlock.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { SkeletonBlock, StandardBlock } from "../../components/sidebar/StandardSidebar.tsx";
import { cn } from "../../utils/classNames.ts";
import { LOADING } from "../../utils/loading.ts";
import { networkCreateLink } from "../api/resourceTypeLinks.ts";
import { getResourceName } from "../resource/getResourceName.tsx";
import { UNKNOWN_RESOURCE } from "./genericResources.ts";
import type { IpAddressWithPrice, LoadBalancerWithPrice } from "./ipAddress/joinAssignedQuery.ts";
import { getDisplayName, UNASSIGNED_RESOURCE } from "./ipAddress/resourceId.ts";
import { ipAddressLink, loadBalancerLink, vpcLink } from "./links.ts";
import { type NetworkListData, type NetworkListItem, useNetworkListQuery } from "./networkListQuery.ts";
import type { VpcWithType } from "./vpc/apiOperations.ts";

const sortFields = [
    {
        id: "address",
        title: "Address",
        compare: addressCompareBy((i) =>
            i.$type === "vpc" ? i.subnet : i.$type === "ip_address" ? i.address : i.private_address,
        ),
    },
    { id: "created_at", title: "Created Date", getValue: (i) => datetimeToDate(i.created_at).getTime() },
    { id: "location", title: "Location", getValue: (i) => i.location },
    { id: "type", title: "Type", getValue: (i) => i.$type },
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => getResourceName(i)),
    },
] satisfies SimpleSortField<NetworkListItem>[];

export function NetworkList() {
    return (
        <SidebarList
            data={(props) => <NetworkData {...props} />}
            title="Network"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Network Resource",
                titleShort: "New",
                action: networkCreateLink,
            }}
            listId="network"
        />
    );
}

export default NetworkList;

export function NetworkData(props: SidebarListState) {
    const query = useNetworkListQuery();
    const { data, isPending } = query;
    return (
        <>
            <QueryErrorBlocks query={query} />
            <NetworkDataInner {...props} isPending={isPending} data={data} />
            {isPending && <SkeletonBlock />}
        </>
    );
}

interface NetworkInnerProps extends SidebarListState {
    data: NetworkListData;
    isPending: boolean;
}

function NetworkDataInner({ queryString, sortId, sortAsc, data, isPending }: NetworkInnerProps): ReactNode {
    let networkItems = getFilteredItems(queryString, data, (item) => {
        switch (item.$type) {
            case "vpc":
                return [item.name, item.subnet];
            case "ip_address":
                return [item.name, item.address];
            case "load_balancer":
                return [item.display_name, item.private_address];
        }
    });
    networkItems = getSortedItems(sortFields, { sortId, sortAsc }, networkItems);

    const networkList = networkItems.map((i) => (
        <Fragment key={i.uuid}>
            {i.$type === "ip_address" ? (
                <AddressBlock item={i} />
            ) : i.$type === "vpc" ? (
                <VpcBlock item={i} />
            ) : (
                <BalancerBlock item={i} />
            )}
        </Fragment>
    ));

    const empty = <EmptyItem icon="jp-access-icon">Create a new resource and start managing your network.</EmptyItem>;

    return networkList.length ? (
        networkList
    ) : queryString ? (
        <NoResults />
    ) : isPending ? /* If we are still loading, we can not know if we are empty, so just show nothing */ null : (
        empty
    );
}

function getAssignedName(ip: IpAddressWithPrice | undefined) {
    const assignedResource = ip?.assignedResource;
    switch (assignedResource) {
        case undefined: // IP address not loaded
        case LOADING:
            return "...";
        case UNKNOWN_RESOURCE:
            return "Unknown";
        case UNASSIGNED_RESOURCE:
            return null;
        default:
            return getDisplayName(assignedResource);
    }
}

export interface AddressBlockProps {
    item: IpAddressWithPrice;
}

function AddressBlock({ item }: AddressBlockProps) {
    const { name, address, location, assigned_to_private_ip } = item;
    const assigned_name = getAssignedName(item);
    return (
        <StandardBlock link={ipAddressLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-network-2-icon size-0.875rem" />
            </div>

            <NamePiece name={name ? name : <i>{assigned_name}</i>} address={address} location={location} />

            <div className={C.StandardBlockType}>
                {assigned_to_private_ip ? (
                    <div className="text-success text-uppercase">Assigned</div>
                ) : (
                    <div className="text-error text-uppercase">Unassigned</div>
                )}
            </div>

            <PriceBlock price={item.price} />
        </StandardBlock>
    );
}

export function VpcBlock({ item }: { item: VpcWithType }) {
    const { name, resources_count, subnet, location, is_default } = item;
    return (
        <StandardBlock link={vpcLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-network-icon size-0.875rem" />
            </div>

            <NamePiece name={name} address={subnet} location={location} />

            <div className={C.StandardBlockInfo}>
                <div className="text-muted">No. of resources</div>
                {resources_count ? resources_count : "0"}
            </div>

            {is_default && (
                <div className={C.holder}>
                    <WTooltip text="Default">
                        <Badge noDot iconOnly inList>
                            <MaskIcon className="jp-icon-checkmark size-0.75rem" />
                        </Badge>
                    </WTooltip>
                </div>
            )}
        </StandardBlock>
    );
}

export interface BalancerBlockProps {
    item: LoadBalancerWithPrice;
}

function BalancerBlock({ item }: BalancerBlockProps) {
    const { display_name, private_address, location, assignedPublicIp } = item;
    const ipAddress = assignedPublicIp?.address;
    return (
        <StandardBlock link={loadBalancerLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-load-balancer-icon size-0.875rem" />
            </div>

            <NamePiece name={display_name} address={ipAddress ?? private_address} location={location} />

            <PriceBlock price={item.price} />
        </StandardBlock>
    );
}

function NamePiece({ name, address, location }: { name: ReactNode; address: ReactNode; location?: ReactNode }) {
    return (
        <div className={cn(C.StandardBlockName, "text-ellipsis")}>
            <b className="text-ellipsis">{name}</b>
            <div className="text-ellipsis text-primary">
                {address}
                {!!location && <> / {location}</>}
            </div>
        </div>
    );
}
