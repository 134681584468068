import T from "../../components/forms/TextField.module.css";
import C from "./Editable.module.css";

import { useRef, useState, type ReactNode } from "react";
import { Dialog, DialogTrigger, Form, Input, Popover, TextField } from "react-aria-components";
import { TODO } from "../../dev/Todo.tsx";
import { isTodoFn } from "../../dev/todoFn.ts";
import { cn } from "../../utils/classNames.ts";
import { usePromiseLoading } from "../../utils/react/usePromiseLoading.ts";
import { WButton } from "../button/WButton.tsx";

/** @see {@link EditableProps} */
export interface EditableTextProps extends Omit<EditableProps<string>, "editor" | "children"> {
    isRequired?: boolean;
    placeholder?: ReactNode;
}

/** Component to edit a string value inline.
 * @see {@link Editable}
 */
export function EditableText({
    defaultValue,
    isRequired,
    ariaLabel,
    validate,
    placeholder,
    ...props
}: EditableTextProps) {
    return (
        <Editable
            defaultValue={defaultValue ?? ""}
            validate={validate ?? ((e) => !isRequired || e !== "")}
            editor={({ defaultValue, onChange, onSubmit }) => (
                // TODO: create a "fake form" component so this can be nested inside a real form?
                // NB: Wrapped in a Form to handle the Enter key
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    <TextField
                        className={cn(T.TextField, T.Editable)}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        // XXX: should we require ariaLabel?
                        aria-label={ariaLabel ?? "Change value"}
                        isRequired={isRequired}
                        autoFocus
                    >
                        <Input className={T.Input} />
                    </TextField>
                </Form>
            )}
            {...props}
        >
            {(value) => (value !== "" ? value : <span className="text-muted">{placeholder}</span>)}
        </Editable>
    );
}

/**
 * @template T - The type of the value being edited.
 */
export interface EditableProps<T> {
    /** If this is set, the component is controlled */
    value?: T | undefined;
    defaultValue?: T;

    defaultIsEditing?: boolean;
    ariaLabel?: string;
    isLoading?: boolean;

    /** Predicate that checks if the value is valid. The editor can not be accepted if the value is not valid. */
    validate?: (e: T) => boolean;
    /** Function to render the value */
    children?: undefined | ((value: T) => ReactNode);
    /** Function to render an editor for the value. `onChange` should be called on every event so validation works in real-time. */
    editor: (props: { defaultValue: T; onChange: (e: T) => void; onSubmit: () => void }) => ReactNode;

    /** Called when the user accepts a new value */
    onChange?: (e: T) => void | Promise<void>;
}

/** Component that either renders a value or displays an inline editor for it. */
export function Editable<T>({
    value,
    defaultValue,
    validate,
    onChange,
    defaultIsEditing,
    isLoading: isLoadingProp,
    children,
    editor,
}: EditableProps<T>) {
    //#region Hooks

    const [isEditing, setIsEditing] = useState(defaultIsEditing ?? false);
    // Current value when uncontrolled. This state will be ignored if we are being controlled.
    const [currentValue, setCurrentValue] = useState<T | undefined>(defaultValue);

    // The value last set by the editor component.
    //   - Will be discarded if the user cancels the edit or clicks outside the popover.
    //   - `undefined` when value has not been modified.
    //   - Wrapped in a ref to avoid re-rendering the component when the value changes. (We only need to update the
    //     `isValid` state.)
    const editorValueRef = useRef<T | undefined>(undefined);

    // Always assume initial value is valid
    const [isValid, setIsValid] = useState(true);

    const { isPromiseLoading, registerPromise } = usePromiseLoading();

    //#endregion

    const isLoading = isLoadingProp ?? isPromiseLoading;

    // If we are being controlled, use the controlled value, otherwise use the internal value
    //      eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- ESLint bug
    const finalValue = value === undefined ? currentValue : value;
    if (typeof finalValue === "undefined") {
        throw new Error("Editable component must have a value or a default value");
    }

    function acceptValue() {
        const editorValue = editorValueRef.current;
        if (editorValue !== undefined) {
            setCurrentValue(editorValue);
            registerPromise(onChange?.(editorValue));
        } // else - the value was not modified

        setIsEditing(false);
    }

    function discardValue() {
        editorValueRef.current = undefined;
        setIsEditing(false);
    }

    function setEditorValue(e: T) {
        editorValueRef.current = e;
        if (validate) {
            const result = validate(e);
            if (result !== isValid) {
                setIsValid(result);
            }
        }
    }

    return (
        <div className={C.Editable}>
            <DialogTrigger>
                <WButton
                    // Hide the content when editing, in case the popover does not cover it completely
                    className={cn(isEditing && "invisible")}
                    action={() => setIsEditing(true)}
                    isLoading={isLoading}
                    variant="editable"
                    icon="jp-icon-edit"
                    iconSide="right"
                >
                    {children ? children(finalValue) : (finalValue as ReactNode)}
                </WButton>

                {isEditing && (
                    <Popover
                        className={C.EditablePopover}
                        defaultOpen={true}
                        // Discard edits when clicking outside the popover
                        onOpenChange={discardValue}
                        placement="bottom start"
                        shouldFlip={false}
                        // XXX: should not be hardcoded, positioning should be dynamic
                        offset={-28}
                    >
                        <Dialog>
                            {editor({ defaultValue: finalValue, onChange: setEditorValue, onSubmit: acceptValue })}

                            <div className={C.EditableFooter}>
                                <WButton
                                    variant="edit"
                                    icon="jp-icon-checkmark"
                                    isDisabled={!isValid}
                                    action={acceptValue}
                                />
                                <WButton variant="edit" icon="jp-icon-close" action={discardValue} />
                            </div>
                        </Dialog>
                    </Popover>
                )}
            </DialogTrigger>
            {isTodoFn(onChange) && <TODO small />}
        </div>
    );
}
