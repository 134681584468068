import { useRouter, type HistoryState } from "@tanstack/react-router";
import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import { last } from "remeda";

/** Update the parameter of a route while keeping the rest of the state */
export function useSimpleRouteParam<TParams extends Record<string, unknown>, const TKey extends keyof TParams>(
    params: TParams,
    key: TKey,
): [TParams[TKey], (value: TParams[TKey]) => void] {
    const router = useRouter();

    return [
        params[key],
        (value) => {
            const currentMatch = last(router.state.matches)!;
            const currentRoute = mustGetProperty(router.looseRoutesById, currentMatch.routeId, "looseRoutesById");
            const to = currentRoute.fullPath as string;
            void router.navigate({
                replace: true,
                to,

                // Keep all the existing state (not necessarily correct...)
                state(prev: HistoryState) {
                    return prev;
                },
                search(current) {
                    return current;
                },
                hash(current: string | undefined) {
                    // TanStack Router does not allow returning `current` directly for some reason...
                    return current ?? "";
                },

                // Update the parameter
                params(current: object) {
                    return { ...current, [key]: value };
                },
            });
        },
    ];
}
