import type { AccessImpersonation } from "@warrenio/api-spec/spec.oats.gen";
import type { ReactNode } from "react";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStartImpersonation } from "../access/impersonation/apiOperations.ts";

export interface ActAsModalProps {
    item: AccessImpersonation;
}

export function ActAsModalContent({ item }: ActAsModalProps) {
    const startImpersonation = useStartImpersonation();
    return (
        <WModalContent
            title="Access User"
            label="Start"
            autoFocus
            modalAction={async () => {
                await startImpersonation.mutateAsync(item);
            }}
        >
            <p className="pb-4">You are about to access user "{item.access_owner}"</p>
            <p className="pb-4">
                The entire user interface will switch and act as you were that user. Once you are finished you can stop
                the session by clicking “Back to your account” button on the header bar and the user interface will
                switch back to your account.
            </p>
            <p>Keep in mind that all the actions will be tracked and can be traced back to you.</p>
        </WModalContent>
    );
}

export function ActAsModal({ button, ...props }: { button?: ReactNode } & ActAsModalProps) {
    return (
        <WModal button={button ? button : <WModalButton inTable label="Act As" />}>
            <ActAsModalContent {...props} />
        </WModal>
    );
}
