import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { WModalOverlay } from "../../components/modal/WModal.tsx";
import { niceOverlayProps } from "../../utils/niceOverlayProps.tsx";
import { ActAsModalContent, type ActAsModalProps } from "./ActAsModal.tsx";

// eslint-disable-next-line react-refresh/only-export-components
const ActAsModalManager = NiceModal.create(function ActAsModalWrapper(props: ActAsModalProps) {
    const modal = useModal();
    return (
        <WModalOverlay {...niceOverlayProps(modal)}>
            <ActAsModalContent {...props} />
        </WModalOverlay>
    );
});

export function showActAsModal(props: ActAsModalProps) {
    discardPromise(NiceModal.show(ActAsModalManager, props));
}
